#btn_edt.spinning:after {
    content: '';
    position: absolute;
    right: 6px;
    top: 50%;
    width: 0;
    height: 0;
    position: absolute;
    border-radius: 50%;
    animation: rotate360 .5s infinite linear, exist .1s forwards ease;
}

#btn_edt.spinning:before {
    content: "";
    width: 0px;
    height: 0px;
    border-radius: 50%;
    right: 6px;
    top: 50%;
    position: absolute;
    border: 2px solid darken(#212121,40%);
    border-right: 3px solid #ffffff;
    animation: rotate360 .5s infinite linear, exist .1s forwards ease ;
    
}



@keyframes rotate360 { 
    100% {
        transform: rotate(360deg);
    }
}
@keyframes exist { 
    100% {
        width: 15px;
        height: 15px;
        margin: -8px 5px 0 0;
    }
}