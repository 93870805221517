.css-awi03e {
  grid-area: 1 / 1 / 2 / 2;
  display: block;
}
.css-17qk605 {
  padding-top: 130px;
  display: block;
}
.css-13o7eu2 {
  display: block;
}

.css-1dsay9e {
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  flex-direction: row;
  margin-inline: 10px;
}
.css-1qdgk8[aria-selected="true"],
.css-1qdgk8[data-selected] {
  background: none;
}
.css-70qvj9 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.css-9p0xzy {
  color: var(--chakra-colors-secondaryGray-900);
  font-size: var(--chakra-fontSizes-lg);
  font-weight: 500;
  margin-inline-end: 12px;
}
.css-trq20r {
  color: var(--chakra-colors-secondaryGray-600);
  font-size: var(--chakra-fontSizes-md);
  font-weight: 400;
}
.css-ezmzth {
  height: 4px;
  width: 100%;
  transition: all 0.1s linear 0s;
  background: var(--chakra-colors-brand-500);
  margin-top: 15px;
  border-radius: 20px;
}
.css-1rbqyc8 {
  outline: transparent solid 2px;
  outline-offset: 2px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  padding-top: var(--chakra-space-2);
  padding-bottom: 0px;
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  border-radius: var(--chakra-radii-full);
  font-weight: var(--chakra-fontWeights-semibold);
  color: var(--chakra-colors-gray-600);
  margin-inline-end: 10px;
  background: unset;
  min-width: max-content;
  flex-direction: column;
}
.css-1gl6llx {
  height: 4px;
  width: 100%;
  transition: all 0.1s linear 0s;
  background: var(--chakra-colors-transparent);
  margin-top: 15px;
  border-radius: 20px;
}
.css-1qdgk8 {
  outline: transparent solid 2px;
  outline-offset: 2px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  padding-top: var(--chakra-space-2);
  padding-bottom: 0px;
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  border-radius: var(--chakra-radii-full);
  font-weight: var(--chakra-fontWeights-semibold);
  color: var(--chakra-colors-gray-600);
  flex-direction: column;
  margin-inline-end: 10px;
  background: unset;
  min-width: max-content;
}
.css-k008qs {
  display: flex;
}
.css-8atqhb {
  width: 100%;
}
.css-adm2jf {
  padding: var(--chakra-space-4);
  outline: transparent solid 2px;
  outline-offset: 2px;
  padding-inline: 0px;
}
.css-1mmor72 {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
}

.css-nqi09b {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  box-shadow: rgba(112, 144, 176, 0.08) 14px 17px 40px 4px;
  min-width: 0px;
  overflow-wrap: break-word;
  height: max-content;
  border-radius: 20px;
  background: border-box rgb(255, 255, 255);
  padding: 20px;
}
.css-nqi09 {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  box-shadow: rgba(112, 144, 176, 0.08) 14px 17px 40px 4px;
  min-width: 0px;
  overflow-wrap: break-word;
  height: max-content;
  border-radius: 20px;
  background: border-box rgb(255, 255, 255);
  padding: 20px;
  background-color: white;
}

.css-1owre1 {
  display: flex;
  flex-direction: column;
}



.css-13srgak {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 20px;
    min-width: 100%;
    min-height: 200px;
    margin-inline-end: 34px;
}
.css-13srga {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 20px;
  min-width: 100%;
  min-height: 200px;
  margin-inline-end: 34px;
}
.css-q6icfi {
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: var(--chakra-colors-white);
  width: 270px;
}

.css-1lzhnyw {
    margin-bottom: 25px;
}
.css-vj7xax {
    display: block;
    min-width: 100%;
    min-height: 100%;
}
.css-k3xqq8 {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: auto;
    padding-top: 30px;
    padding-bottom: 0px;
}
.css-1svo1r5 {
  color: var(--chakra-colors-secondaryGray-600);
  font-size: var(--chakra-fontSizes-md);
  font-weight: 400;
  margin-inline-end: 14px;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Nombre maximum de lignes */
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.7em;
}
.css-j156tp {
  margin-inline-start: auto;
  margin-top: 10px;
  color: var(--chakra-colors-secondaryGray-600);
  font-size: var(--chakra-fontSizes-md);
  font-weight: 500;
}
.css-1oxmu54 {
  color: var(--chakra-colors-navy-700);
  font-size: var(--chakra-fontSizes-md);
  font-weight: 500;
  margin-inline-start: 4px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding-bottom: 20px;
  padding-right: 30px;
}
.css-1257dch {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.css-eg4ddt {
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: var(--chakra-colors-navy-700);
  margin-inline-end: 6px;
}
.css-99guxa {
  color: var(--chakra-colors-navy-700);
  font-size: var(--chakra-fontSizes-sm);
  font-weight: 500;
  margin-inline-end: 14px;
}
.css-13ydyn2 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-inline-start: 0px;
    padding-inline-end: 10px;
}
.css-1hgd3j {
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    padding-inline-start: var(--chakra-space-1);
    padding-inline-end: var(--chakra-space-1);
    text-transform: uppercase;
    font-size: var(--chakra-fontSizes-xs);
    border-radius: 10px;
    font-weight: var(--chakra-fontWeights-bold);
    box-shadow: var(--badge-shadow);
    line-height: 100%;
    padding: 7px 12px;
    --badge-bg: var(--chakra-colors-gray-100);
    --badge-color: var(--chakra-colors-gray-800);
    background: var(--chakra-colors-secondaryGray-300);
    text-align: center;
    margin-bottom: 20px;
    color: var(--chakra-colors-brand-500);
    margin-inline-end: 10px;
    height: max-content;
}
.css-14bqeo6 {
    color: var(--chakra-colors-navy-700);
    font-size: var(--chakra-fontSizes-xl);
    margin-bottom: 10px;
    font-weight: 700;
}
@media screen and (min-width: 320px) {
  .css-1dsay9e {
    overflow-x: scroll;
  }
  .css-nqi09 {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .css-17qk605 {
    padding-top: 80px;
    grid-template-columns: 2.15fr 1fr;
  }
  .css-nqi09b {
    min-height: 400px;
  }
  .css-nqi09 {
    min-height: 400px;
    width: 70%;
  }
  .css-1owre1 {
    flex-direction: column;
  }
  .css-k3xqq8 {
    padding-bottom: 0px;
}
.css-14bqeo6 {
  font-size: var(--chakra-fontSizes-xl);
}
.css-1hgd3j {
  margin-bottom: 0px;
}
.css-13ydyn2 {
  padding-inline-end: 0px;
}

}
@media screen and (min-width: 960px) {
  .css-17qk605 {
    display: grid;
  }
  .css-1dsay9e {
    margin-inline: 30px;
    overflow-x: unset;
  }
  .css-awi03e {
    display: flex;
  }
}
@media screen and (min-width: 1200px) {
  .css-17qk605 {
    padding-top: 80px;
    grid-template-columns: 2.95fr 0.05fr 1fr;
  }
  .css-nqi09b {
    min-height: auto;
  }
  .css-nqi09 {
    min-height: auto;
    max-width: 60%;
  }
  .css-1owre1 {
    flex-direction: row;
  }
  .css-13srgak {
    min-width: 270px;
    min-height: 270px;
}
.css-13srga {
  min-width: 270px;
  min-height: 270px;
}
.css-vj7xax {
  display: flex;
  min-width: 100%;
  min-height: 100%;
}
.css-1lzhnyw {
  width: 100%;
}
.css-14bqeo6 {
  font-size: var(--chakra-fontSizes-xl);
}

.css-13ydyn2 {
  margin-inline-start: auto;
}
}
.css-6lxoxy {
  display: flex;
  width: 1px;
  margin-inline-start: 30px;
  margin-inline-end: 30px;
  height: 100%;
  background: rgba(135, 140, 189, 0.3);
}