.css-1ofqig9 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.css-1athpoa {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.css-jr93ze {
  margin-bottom: 26px;
}
.css-c90d97 {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  box-shadow: rgba(112, 144, 176, 0.08) 14px 17px 40px 4px;
  min-width: 0px;
  overflow-wrap: break-word;
  padding: 20px 20px 30px;
  border-radius: 20px;
  background: border-box;
}
.css-8y8lw5 {
  float: right;
  min-height: 100vh;
  height: 100%;
  position: relative;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden!important;
  transition: top 0.2s linear 0s, bottom 0.2s linear, width 0.35s ease;
}
.css-1wv9e5 {
  margin-inline: auto;
  padding-inline-end: 20px;
  min-height: 100vh;
  padding: 30px 10px 10px!important;
}

.css-j7qwjs {
  display: flex;
  flex-direction: column;
}
.css-8bclud {
  color: var(--chakra-colors-secondaryGray-900);
  font-size: var(--chakra-fontSizes-3xl);
  font-weight: var(--chakra-fontWeights-bold);
  margin-bottom: 12px;
}
.css-ctbaqr {
  display: flex;
}
.css-dubuwf {
  padding-inline-end: 0px;
  margin-bottom: 40px;
  text-align: justify;
  font-weight: 500;
}
.css-dubuw {
  padding-inline-end: 0px;
  margin-bottom: 10px;
  text-align: justify;
  font-weight: 100;
}
.css-1a08lwf {
  margin-bottom: 16px;
}
.css-1t7rcca {
  overflow-anchor: none;
  border: var(--chakra-borders-none);
}
.css-isyi3h {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  outline: transparent solid 2px;
  outline-offset: 2px;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  padding: 20px 0px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--chakra-colors-secondaryGray-400);
}
.css-1eziwv {
  flex: 1 1 0%;
  text-align: left;
}
.css-1tx3t1v {
  color: var(--chakra-colors-secondaryGray-900);
  font-weight: 700;
}
.css-hd9xtg {
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: var(--chakra-colors-gray-500);
  opacity: 1;
  transform: rotate(-180deg);
  transition: transform 0.2s ease 0s;
  transform-origin: center center;
  font-size: 1.25em;
  vertical-align: middle;
}
.css-1yhgnh7 {
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  padding: 18px 0px 10px;
}
.css-1xah0mr {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 320px) {
  .css-c90d97 {
    margin-top: 125px;
  }
  .css-jr93ze {
    height: 100%;
    margin-inline: auto;
  }
  .css-8bclud {
    margin-top: 20px;
  }
  .css-1tx3t1v {
    font-size: var(--chakra-fontSizes-md);
  }
  .css-ctbaqr {
    flex-direction: column;
  }
  .css-o6kj28 {
    display: flex;
    width: min-content;
    padding: 0px 0px 15px 0px;
    gap: 10px;
}

}
@media screen and (min-width: 768px) {
  .css-c90d97 {
    margin-top: 75px;
  }
  .css-jr93ze {
    height: 670px;
  }
  .css-8bclud {
    margin-top: 50px;
  }
  .css-ctbaqr {
    flex-direction: column;
  }
}
@media screen and (min-width: 960px) {
  .css-jr93ze {
    width: 800px;
    height: 600px;
    margin-inline: auto;
  }
  .css-1tx3t1v {
    font-size: var(--chakra-fontSizes-md);
  }
  .css-1xah0mr {
    margin-inline-end: 40px;
    margin-bottom: 0px;
  }
  .css-ctbaqr {
    flex-direction: column;
  }
}
@media screen and (min-width: 1200px) {
  .css-jr93ze {
    width: 500px;
    height: 600px;
    margin-inline: 0px;
  }
  .css-1xah0mr {
    margin-inline-end: 60px;
  }
  .css-ctbaqr {
    flex-direction: row;
  }
}
@media screen and (min-width: 1600px) {
  .css-1athpoa {
    width: 100%;
    height: 100%;
    margin-inline: 15px;
  }
  .css-1xah0mr {
    margin-inline-end: 60px;
  }
  .css-ctbaqr {
    flex-direction: row;
  }
}
