@keyframes ldio-7ucssw0ngdl {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.ldio-7ucssw0ngdl div {
  position: absolute;
  animation: ldio-7ucssw0ngdl 1s linear infinite;
  width: 80px;
  height: 80px;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 0 0 #1d3f72;
  transform-origin: 40px 41px;
}
.loadingio-spinner-eclipse-cjumf2xvq5v {
  position: absolute;
  width: 58px;
  height: 58px;
  display: inline-block;
  overflow: hidden;
  background: none;
  margin-top: 47%;
  margin-left: 47%;
  z-index: 1;
}
.ldio-7ucssw0ngdl {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateZ(0) scale(0.58);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-7ucssw0ngdl div { box-sizing: content-box; }
/* generated by https://loading.io/ */
