.css-138tzn1 {
  display: flex;
  flex-direction: column;
}
.css-1wv9e55 {
  margin-inline: auto;
  padding: 50px 20px 20px;
  padding-inline-end: 20px;
  min-height: 100vh;
}
.css-1dxzs3n {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  box-shadow: rgba(112, 144, 176, 0.08) 14px 17px 40px 4px;
  min-width: 0px;
  overflow-wrap: break-word;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding: 20px 20px 30px;
  border-radius: 20px;
}
.css-u6kgca {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.css-aksx7v {
  display: flex;
  position: relative;
  isolation: isolate;
  height: 44px;
}
.css-fd4b8v {
  display: flex;
  width: 100%;
  padding-inline: 22px;
  margin-bottom: 36px;
}
.css-1hx7vxy {
  left: 0px;
  width: var(--chakra-sizes-10);
  height: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  z-index: 2;
}
.css-gs336o {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: var(--chakra-fontWeights-semibold);
  box-shadow: unset;
  transition: all 0.25s ease 0s;
  box-sizing: border-box;
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  padding: 0px;
  border-radius: inherit;
  background: inherit;
}
.css-mqq5mn {
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: var(--chakra-colors-gray-700);
  width: 15px;
  height: 15px;
}
.css-7fs23v {
  text-align: start;
  padding-inline-start: var(--chakra-space-6);
  padding-inline-end: var(--chakra-space-6);
  padding-top: var(--chakra-space-4);
  padding-bottom: var(--chakra-space-4);
  line-height: var(--chakra-lineHeights-5);
  border-color: var(--chakra-colors-gray-200);
}
.css-wlrsyp {
  font-variant-numeric: lining-nums tabular-nums;
  border-collapse: collapse;
  width: var(--chakra-sizes-full);
  color: var(--chakra-colors-gray-500);
  margin-bottom: 24px;
  width: 100%;
}
.css-mzhhie {
  width: 100%;
  min-width: 0px;
  outline: transparent solid 2px;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  padding-inline-start: var(--chakra-space-10);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border: var(--chakra-borders-none);
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: var(--chakra-fontSizes-sm);
  background: var(--chakra-colors-secondaryGray-300);
  color: var(--chakra-colors-gray-700);
  font-weight: 500;
  border-radius: 16px;
}
.css-1ymzhfb {
  font-family: var(--chakra-fonts-heading);
  font-weight: var(--chakra-fontWeights-bold);
  text-transform: uppercase;
  letter-spacing: var(--chakra-letterSpacings-wider);
  text-align: start;
  padding-inline-start: var(--chakra-space-6);
  padding-inline-end: 10px;
  padding-top: var(--chakra-space-3);
  padding-bottom: var(--chakra-space-3);
  line-height: var(--chakra-lineHeights-4);
  font-size: var(--chakra-fontSizes-xs);
  color: var(--chakra-colors-gray-600);
  border-color: var(--chakra-colors-gray-200);
  cursor: pointer;
}
.css-1w7gwv1 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  color: var(--chakra-colors-gray-400);
}
.css-1gv374f {
    color: var(--chakra-colors-secondaryGray-900);
    font-size: var(--chakra-fontSizes-md);
    font-weight: 500;
}
.css-3a5bz2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--chakra-radii-full);
}
.css-1jaro82 {
    cursor: pointer;
    color: var(--chakra-colors-brand-500);
    text-decoration: underline;
    font-size: var(--chakra-fontSizes-md);
    font-weight: 500;
}
.css-1n5oilq {
    border-radius: var(--chakra-radii-full);
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: var(--chakra-fontWeights-medium);
    position: relative;
    flex-shrink: 0;
    background: var(--avatar-background);
    color: var(--chakra-colors-white);
    --avatar-border-color: var(--chakra-colors-white);
    border-color: var(--avatar-border-color);
    vertical-align: top;
    width: 60px;
    height: 60px;
    font-size: calc(1.2rem);
    margin-inline-end: 10px;
}
.css-70qvj9 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
@media screen and (min-width: 320px) {
  .css-138tzn1 {
    padding-top: 125px;
  }
  .css-u6kgca {
    overflow-x: scroll;
  }
  .css-fd4b8v {
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .css-1w7gwv1 {
    font-size: 10px;
  }
  .css-7fs23v {
    font-size: 14px;
    min-width: 150px;
  }
}
@media screen and (min-width: 768px) {
  .css-1wv9e55 {
    padding: 30px;
  }
  .css-7fs23v {
    min-width: 200px;
  }
}
@media screen and (min-width: 960px) {
  .css-aksx7v {
    width: 390px;
  }
  .css-138tzn1 {
    padding-top: 75px;
  }
  .css-u6kgca {
    overflow-x: hidden;
  }
  .css-fd4b8v {
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .css-1w7gwv1 {
    font-size: 12px;
  }
  .css-7fs23v {
    min-width: auto;
  }
}
@media screen and (min-width: 1200px) {
}
