/* .carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #111c44;
    border-radius: 20px;
    margin-top: 20px;
    min-height: 304px;
    min-width: 380px;
} */

.sldle {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px #666;
    background-color: aqua;
}

.slide-hidden {
    display: none;
}

.arrow {
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: white;
    filter: drop-shadow(0px 0px 3px #555);
}

.arrow:hover {
    cursor: pointer;
}

.arrow-left {
    left: 1rem;
}

.arrow-right {
    right: 1rem;
}

.indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
}

.indicator {
    background-color: white;
    height: 0.3rem;
    width: 1rem;
    border-radius: 30%;
    border: none;
    outline: none;
    /* box-shadow: 0px 0px 5px #555; */
    margin: 0 0.2rem;
    cursor: pointer;
}

.indicator-inactive {
    background-color: grey;
    height: 0.3rem;
    width: 0.3rem;
    border-radius: 100%;
}
