/* @media screen and (max-width: 1536px) {
    #noResponsiveBox {
        display: none;
    }
    #responsiveBox {
        display: flex;
    }
} */
#noResponsiveBox {
    display: none;
}
#responsiveBox {
    display: flex;
}