.tabButton {
  outline: transparent solid 2px;
  outline-offset: 2px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  padding-top: var(--chakra-space-2);
  padding-bottom: var(--chakra-space-2);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
}

.opacity-button{
    opacity: 0.6;
}

.tabButton .tabButtonItem {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
}

.tabButton {
  outline: transparent solid 2px;
  outline-offset: 2px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  padding-top: var(--chakra-space-2);
  padding-bottom: var(--chakra-space-2);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
}

button {
  background: transparent;
  padding: 0px;
}

.tabButtonItemText {
  color: var(--chakra-colors-white);
  font-weight: var(--chakra-fontWeights-bold);
}

.tabButton .tabButtonItem::before {
  content: "";
  height: 3px;
  background: var(--chakra-colors-brand-400);
  position: absolute;
  bottom: 40px;
  transition: all 0.3s ease 0s;
}
.tabButton .tabButtonItem.active::before{
    background: var(--chakra-colors-white);
}

.tabButton .tabButtonItem.tabButtonItemLast::before {
  height: 0px;
  background: none;
  position: inherit;
  bottom: 0px;
  transition: none;
}
*,
::before,
::after {
  border-color: var(--chakra-colors-chakra-border-color);
}

.tabButton .tabButtonItem .tabButtonElement {
  z-index: 1;
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  border-color: var(--chakra-colors-white);
  background-image: linear-gradient(
    to bottom,
    var(--chakra-colors-brand-400),
    var(--chakra-colors-brand-600)
  );
  width: 16px;
  height: 16px;
  margin-bottom: 8px;
  border-radius: 50%;
}
.contentTabComponent {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
}

.contentForms {
  margin-top: 24px;
  margin-inline: auto;
  width: 100%;
}

.formTab {
  outline: transparent solid 2px;
  outline-offset: 2px;
  padding: 0px;
  margin-inline: auto;
}

.css-ulukh4 {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  box-shadow: rgba(112, 144, 176, 0.08) 14px 17px 40px 4px;
  min-width: 0px;
  overflow-wrap: break-word;
  border-radius: 20px;
  background: border-box rgb(255, 255, 255);
  padding: 30px;
}
.css-8rszb0 {
  color: var(--chakra-colors-secondaryGray-900);
  font-size: var(--chakra-fontSizes-2xl);
  font-weight: 700;
  margin-bottom: 20px;
}
.css-1ofqig9 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.css-1rrbyyl {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, minmax(0px, 1fr));
}
.css-vuscuc {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.css-1doh6tj {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}
.css-186ooiv {
  text-align: start;
  margin-inline-end: var(--chakra-space-3);
  margin-bottom: var(--chakra-space-2);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  opacity: 1;
  display: flex;
  margin-inline-start: 10px;
  font-size: var(--chakra-fontSizes-sm);
  color: var(--chakra-colors-secondaryGray-900);
  font-weight: var(--chakra-fontWeights-bold);
}

.css-1t1l5ix {
  width: 100%;
  min-width: 0px;
  outline: transparent solid 2px;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  border-radius: 16px;
  font-size: var(--chakra-fontSizes-sm);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  background: var(--chakra-colors-transparent);
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  color: var(--chakra-colors-secondaryGray-900);
  border-color: var(--chakra-colors-secondaryGray-100);
  padding: 7px 20px;
  font-weight: 500;
  height: 44px;
}
.css-16s7o2u {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 24px;
}
.css-cejpi7 {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: var(--chakra-colors-gray-100);
  border-width: 1px;
  border-style: dashed;
  border-image: initial;
  border-color: var(--chakra-colors-gray-300);
  border-radius: 16px;
  width: 100%;
  max-width: 100%;
  height: max-content;
  min-height: 130px;
  cursor: pointer;
  padding-top: 80px !important;
  padding-bottom: 105px !important;
}
.css-1tacfe8 {
  width: 100%;
  min-width: 0px;
  outline: transparent solid 2px;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-weight: 400;
  border-radius: 16px;
  font-size: var(--chakra-fontSizes-sm);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  background: var(--chakra-colors-transparent);
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  color: var(--chakra-colors-secondaryGray-900);
  border-color: var(--chakra-colors-secondaryGray-100);
  padding: 20px;
}
.css-1llmklb {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: 16px;
  font-weight: var(--chakra-fontWeights-semibold);
  box-shadow: unset;
  transition: all 0.25s ease 0s;
  box-sizing: border-box;
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
}
.css-16s7o2u {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 24px;
}

.css-1id0lme {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: var(--chakra-fontWeights-semibold);
  box-shadow: unset;
  transition: all 0.25s ease 0s;
  box-sizing: border-box;
  min-width: var(--chakra-sizes-10);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  background: var(--chakra-colors-secondaryGray-300);
  color: var(--chakra-colors-secondaryGray-900);
  font-size: var(--chakra-fontSizes-sm);
  border-radius: 16px;
  width: 128px;
  height: 46px;
}
.css-184xkan {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: var(--chakra-fontWeights-semibold);
  box-shadow: unset;
  transition: all 0.25s ease 0s;
  box-sizing: border-box;
  min-width: var(--chakra-sizes-10);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  background: var(--chakra-colors-brand-900);
  color: var(--chakra-colors-white);
  font-size: var(--chakra-fontSizes-sm);
  border-radius: 16px;
  width: 128px;
  height: 46px;
  margin-inline-start: auto;
}
.css-xtktxr {
  width: 100%;
  min-width: 0px;
  outline: transparent solid 2px;
  outline-offset: 2px;
  position: relative;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  padding-top: var(--chakra-space-2);
  padding-bottom: var(--chakra-space-2);
  min-height: var(--chakra-sizes-20);
  line-height: var(--chakra-lineHeights-short);
  vertical-align: top;
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: 146px;
  color: var(--chakra-colors-secondaryGray-900);
  font-size: var(--chakra-fontSizes-sm);
  background: var(--chakra-colors-transparent);
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: var(--chakra-colors-secondaryGray-100);
  border-radius: 16px;
}
.css-xtktxr:focus-visible,
.css-xtktxr[data-focus-visible], 
.css-1t1l5ix:focus-visible,
.css-1t1l5ix[data-focus-visible] {
  z-index: 1;
  border-color: rgb(57, 101, 255);
  box-shadow: rgb(57, 101, 255) 0px 0px 0px 1px;
}
.css-184xkan:hover,
.css-184xkan[data-hover] {
  background: var(--chakra-colors-brand-800);
}

.css-dtf1yy {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: var(--chakra-fontWeights-semibold);
  box-shadow: unset;
  transition: all 0.25s ease 0s;
  box-sizing: border-box;
  min-width: var(--chakra-sizes-10);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  background: var(--chakra-colors-brand-900);
  color: var(--chakra-colors-white);
  font-size: var(--chakra-fontSizes-sm);
  border-radius: 16px;
  width: 128px;
  height: 46px;
}
.css-cltr4i {
    margin-inline: auto;
    margin-bottom: 12px;
    font-size: var(--chakra-fontSizes-lg);
    font-weight: 700;
    white-space: pre-wrap;
    color: var(--chakra-colors-secondaryGray-900);
}
.css-1dbbp9i {
    font-size: var(--chakra-fontSizes-sm);
    font-weight: 500;
    color: var(--chakra-colors-secondaryGray-500);
}
.css-c5zb31 {
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    color: var(--chakra-colors-secondaryGray-900);
    width: 80px;
    height: 80px;
}
button {
  background: transparent;
  padding: 0px;
}
@media screen and (min-width: 320px) {
  .tabButton .tabButtonItem::before {
    width: 120px;
    left: 12px;
    top: 6px;
  }
  .tabButton .tabButtonItem.tabButtonItemLast::before {
    width: 0px;
  }
  .tabButton {
    width: 120px;
  }
  .tabButtonItemText {
    display: none;
  }
  .contentTabComponent {
    padding-top: 75px;
  }
  .formTab {
    width: 330px;
  }
}
@media screen and (min-width: 768px) {
  .tabButton .tabButtonItem::before {
    width: 250px;
  }
  .tabButton .tabButtonItem.tabButtonItemLast::before {
    width: 0px;
  }
  .css-dtf1yy {
    width: 148px;
  }
  .tabButton {
    width: 250px;
  }
  .tabButtonItemText {
    display: block;
  }
  .contentForms {
    max-width: 90%;
  }
  .formTab {
    width: 700px;
  }
  .css-1rrbyyl {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
  .css-184xkan {
    width: 148px;
  }
  .css-1id0lme {
    width: 148px;
  }
}
@media screen and (min-width: 960px) {
  .tabButton .tabButtonItem::before {
    width: 300px;
  }
  .tabButton {
    width: 300px;
  }

  .contentTabComponent {
    padding-top: 125px;
  }
  .contentForms {
    max-width: 100%;
  }
  .formTab {
    width: 850px;
  }
}
#btn_post.spinning {
  padding-right: 40px;
}

#btn_post.spinning:after {
  content: '';
  position: absolute;
  right: 6px;
  top: 50%;
  width: 0;
  height: 0;
  position: absolute;
  box-shadow: 0px 0px 0 1px darken(#212121, 10%);
  border-radius: 50%;
  animation: rotate360 .5s infinite linear, exist .1s forwards ease;
}

#btn_post.spinning:before {
  content: "";
  width: 0px;
  height: 0px;
  border-radius: 50%;
  right: 6px;
  top: 50%;
  position: absolute;
  border: 2px solid darken(#212121,40%);
  border-right: 3px solid #27ae60;
  animation: rotate360 .5s infinite linear, exist .1s forwards ease ;
  
}



@keyframes rotate360 { 
  100% {
      transform: rotate(360deg);
  }
}
@keyframes exist { 
  100% {
      width: 15px;
      height: 15px;
      margin: -8px 5px 0 0;
  }
}
